<template>
  <div>
    <div
      class="home_bg"
    >
      <!--
<div class="h-100 d-flex justify-content-between align-items-center ">

      <div class="  flex-fill   d-flex flex-column   color-dark">
        <div class="p-1">
          يمكنك الذهاب الى القائمة
          الذكية عبر الضغط على الزر
        </div>
        <div class="d-flex justify-content-center py-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            :to="{ path: 'product' }"
          >
            الى القائمة الذكية
          </b-button>
        </div>

      </div>

      <div class="flex-fill">
        dsds
      </div>

</div> -->

      <b-row class="px-responsive h-100">

        <b-col
          lg="6"
          sm="12"
        >
          <div class="  flex-fill   d-flex flex-column   color-dark">
            <div class="p-1 home-text">

              {{ getCookie("lang") === "en" ? "You can go to Smart Menu by clicking here" : "  يمكنك الذهاب الى القائمة الذكية عبر الضغط على الزر"
              }}

            </div>
            <div class="d-flex justify-content-center py-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                :to="{ name: 'product' }"
              >
                {{ getCookie("lang") === "en" ? "To Smart Menu" : "  الى القائمة الذكية  "
                }}
              </b-button>
            </div>

          </div>
        </b-col>
        <b-col
          lg="6"
          sm="12"
        />
      </b-row>

    </div>

  </div>
</template>

<script>
import { BButton, BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BButton, BRow, BCol,
  },
  methods: {
    getCookie(name) {
      const nameEQ = `${name}=`
      const ca = document.cookie.split(';')
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) == ' ') c = c.substring(1, c.length)
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
      }
      return null
    },
  },
}
</script>
